import React from 'react';

function Footer() {
  const textToCopy = "0xC1A783d1FD04D9d8fb6B996e6092baBAC9F32BFa";

  const handleCopyClick = () => {
    navigator.clipboard.writeText(textToCopy);
    //alert("Wallet address copied to clipboard!"); // Optional: give feedback to the user
  };

  return (
    <footer style={footerStyle}>
      <p>If you found this useful, why not throw some Battle Plan! credits my way</p>
      <p onClick={handleCopyClick} style={copyTextStyle}><b>{textToCopy} </b><span onClick={handleCopyClick} style={iconStyle}>📋</span></p>
    </footer>
  );
}

const footerStyle = {
  backgroundColor: 'transparent',
  color: 'white',
  textAlign: 'center',
  padding: '5px 10px',
  position: 'fixed',
  left: '0',
  bottom: '0',
  width: '100%',
  fontSize: '16px',
};

const iconStyle = {
  cursor: 'pointer',
  marginLeft: '5px',
};

const copyTextStyle = {
    cursor: 'pointer',
    fontSize: '20px'
  };

export default Footer;
