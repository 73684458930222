import React from 'react';
import './App.css';
import DataFetcher from './DataFetcher'; // Ensure the path is correct
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        {/* Other components or content can go here */}
        <DataFetcher />
        {/* You can add more content or components here */}
        <Footer />
      </header>
    </div>
  );
}

export default App;
