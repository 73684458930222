import React, { useState } from "react";

function DataFetcher() {
  const inputStyle = {
    width: "400px",
    fontSize: "16px",
    padding: "10px",
  };

  const tableContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column", // This stacks the total and the table vertically
    width: "100%", // Ensures the container takes the full width of its parent
    marginTop: "20px", // Adds some space above the table
  };
  const rightAlignStyle = {
    textAlign: "right",
    padding: "0 10px", // Adjusts right alignment and adds horizontal padding
  };

  const leftAlignStyle = {
    padding: "0 10px", // Adds horizontal padding for the left-aligned cells
  };

  const [inputValue, setInputValue] = useState("");
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setData(null); // Clear the data when input changes
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        //`https://z9rhs6943m.execute-api.us-east-1.amazonaws.com/v1/wallet?addr=${inputValue}`
        `https://api.ragequitchecker.com?addr=${inputValue}`
      );
      const jsonData = await response.json();
      setData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="header">
        Ragequit Community Shares
      </h1>
      <h4>Larger wallets will take some time (a minute or more). Be patient, don't refresh.</h4>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        style={inputStyle}
      />
      <button onClick={fetchData} className="button">
        Check Wallet
      </button>

      {loading && (
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      )}

      {data && (
      <div style={tableContainerStyle}>
          <h3>Your $gg from Community Pool: {data.total.toLocaleString()}</h3>
          {data.dust.length <= 100 ? (
            <table>
              <thead>
                <tr>
                  <th style={leftAlignStyle}>Dust Id</th>
                  <th style={rightAlignStyle}>Shares</th>
                </tr>
              </thead>
              <tbody>
                {data.dust.map((item) => (
                  <tr key={item.id}>
                    <td style={leftAlignStyle}>{item.id}</td>
                    <td style={rightAlignStyle}>{item.rewards}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            ) : (
              <p>More than 100 dust. That's a lot. Well done!</p>
            )}
            </div>
      )}
    </div>
  );
}

export default DataFetcher;
